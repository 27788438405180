<template>
  <div>
    <CRow>
      <CCard class="col-xl-12">
        <CCardHeader>
          <CButtonGroup>
            <CButton :class="{'btn-time-disactive':type_time!='specific', 'btn-time-active':type_time=='specific'}" @click="changeTime('specific')">Specific Time</CButton>
            <CButton :class="{'btn-time-disactive':type_time!='latest', 'btn-time-active':type_time=='latest'}" @click="changeTime('latest')">Latest Time</CButton>
            <CButton :class="{'btn-time-disactive':type_time!='range', 'btn-time-active':type_time=='range'}" @click="changeTime('range')">Range Time</CButton>
          </CButtonGroup>
        </CCardHeader>
        <CCardBody>
          <CCol xl="12" style="text-align: center;" v-if="isHLoading">
            <CSpinner
              color="info"
              style="width:1rem;height:1rem;"
            />
          </CCol>
          <CRow v-if="!isHLoading">
            <CCol tag="label" xl="1" class="col-form-label font-weight-bold padding-0 font-08">
              Lanud:
            </CCol>
            <CCol xl="11" class="padding-0" style="padding-right: 10px;">
              <v-select 
                  :reduce="airports => airports.value"
                  v-model="airport_id"
                  :options="airports"
                  placeholder="Please select air base"
              />
            </CCol>
            <CCol xl="12" class="padding-0"><small class="form-text text-muted w-100">{{errors.airport_id}}</small></CCol>
            <CCol v-if="type_time=='specific'" xl="12">
              <CRow>
                <CCol tag="label" xl="1" class="col-form-label font-weight-bold padding-0 font-08">
                  Time:
                </CCol>
                <CCol xl="11" class="padding-0" style="padding-right: 10px;">
                  <date-picker 
                    v-model="sform.time" 
                    placeholder="Please fill time"
                    type="datetime" 
                    value-type="format" 
                    format="YYYY-MM-DD HH:mm"
                    style="width: 100%;">
                  </date-picker>
                </CCol>
                <CCol xl="12" class="padding-0"><small class="form-text text-muted w-100">{{errors.time}}</small></CCol>
              </CRow>
            </CCol>
            <CCol v-if="type_time=='range'" xl="12">
              <CRow>
                <CCol tag="label" xl="1" class="col-form-label font-weight-bold padding-0 font-08">
                  Start Date:
                </CCol>
                <CCol xl="5" class="padding-0" style="padding-right: 10px;">
                  <date-picker 
                    v-model="rform.start_date" 
                    placeholder="Please fill start date"
                    type="datetime" 
                    value-type="format" 
                    format="YYYY-MM-DD HH:mm"
                    style="width: 100%;">
                  </date-picker>
                </CCol>
                <CCol tag="label" xl="1" class="col-form-label font-weight-bold padding-0 font-08">
                  End Date:
                </CCol>
                <CCol xl="5" class="padding-0" style="padding-right: 10px;">
                  <date-picker 
                    v-model="rform.end_date" 
                    placeholder="Please fill end date"
                    type="datetime" 
                    value-type="format" 
                    format="YYYY-MM-DD HH:mm"
                    style="width: 100%;">
                  </date-picker>
                </CCol>
                <CCol xl="6" class="padding-0"><small class="form-text text-muted w-100">{{errors.start_date}}</small></CCol>
                <CCol xl="6" class="padding-0"><small class="form-text text-muted w-100">{{errors.end_date}}</small></CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter v-if="!isHLoading">
            <CButton @click="showReport()" color="primary">
                Generate Report
            </CButton>
        </CCardFooter>
      </CCard>
      <CCard v-if="show=='specific'" class="col-xl-12 padding-0">
        <CCardHeader>
            <CButton @click="printReport('specific')" color="light" class="float-right">
                <v-icon name="print"/> Print
            </CButton>
        </CCardHeader>
        <CCardBody>
          <CRow class="margin-0">
            <CCol xl="12" class="padding-0">
              <div class="position-relative table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>METEOROLOGICAL OBS AT DATE TIME</td>
                            <td>{{this.sdata.meteorological}}</td>
                        </tr>
                        <tr>
                            <td>AERODROME IDENTIFICATION</td>
                            <td>{{this.sdata.aerodrome_identification}}</td>
                        </tr>
                        <tr>
                            <td>SURFACE WIND DIRECTION, SPEED AND SIGNIFICANT VARIATION</td>
                            <td><span v-html="this.sdata.wind_modified"></span></td>
                        </tr>
                        <tr>
                            <td>HORIZONTAL VISIBILITY</td>
                            <td><span v-html="this.sdata.visibility_modified"></span></td>
                        </tr>
                        <tr>
                            <td>RUNWAY VISUAL RANGE</td>
                            <td><span v-html="this.sdata.rvr_modified"></span></td>
                        </tr>
                        <tr>
                            <td>PRESENT WEATHER</td>
                            <td><span v-html="this.sdata.present_weather_modified"></span></td>
                        </tr>
                        <tr>
                            <td>AMOUNT AND HEIGHT OF BASE OF LOW CLOUD</td>
                            <td><span v-html="this.sdata.sky_condition_modified"></span></td>
                        </tr>
                        <tr>
                            <td>AIR TEMPERATURE AND DEW POINT TEMPERATURE</td>
                            <td><span v-html="this.sdata.air_temperature_modified"></span></td>
                        </tr>
                        <tr>
                            <td>QNH</td>
                            <td><span v-html="this.sdata.qnh_modified"></span></td>
                        </tr>
                        <tr>
                            <td>QFE*</td>
                            <td><span v-html="this.sdata.qfe_modified"></span></td>
                        </tr>
                        <tr>
                            <td>SUPLEMENTARY INFORMATION</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TIME OF ISSUE</td>
                            <td>{{this.sdata.timeIssue}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard v-if="show=='range'" class="col-xl-12 padding-0">
        <CCardHeader>
            <CButton @click="printReport('range')" color="light" class="float-right">
                <v-icon name="print"/> Print
            </CButton>
        </CCardHeader>
        <CCardBody class="p-2">
          <CRow class="margin-0">
            <CCol xl="12" class="padding-0 text-center">
              <div class="v-mid-tab thable-center tableof-150" style="text-align: center; overflow-x: auto;">
                <div class="position-relative table-responsive">
                  <table v-if="rdata.rows.length>0" class="table table-striped table-fixed table-hover table-bordered">
                    <thead>
                      <tr>
                        <th rowspan="2" style="vertical-align: middle">Observer Time</th>
                        <th v-for="(ths, ith) in tableHeaders" :key="'th'+ith" colspan="2">{{ ths }}</th>
                      </tr>
                      <tr v-if="runways.length<2">
                        <th v-for="(rcell, irc) in tblrunways" :key="'tr'+irc" colspan="2">{{ rcell }}</th>
                      </tr>
                      <tr v-if="runways.length>1">
                        <th v-for="(rcell, irc) in tblrunways" :key="'tr'+irc">{{ rcell }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="runways.length<2">
                      <tr v-for="(rd, ird) in rdata.rows" :key="ird">
                        <td class="text-left">{{ rd.observer_time }}</td>
                        <td colspan="2" v-for="(rdwd, iwd) in rd.wind_direction" :key="'wd'+iwd">{{ rdwd }}</td>
                        <td colspan="2" v-for="(rdws, iws) in rd.wind_speed" :key="'ws'+iws">{{ rdws }}</td>
                        <td colspan="2" v-for="(rdv, iv) in rd.visibility" :key="'v'+iv">{{ rdv }}</td>
                        <td colspan="2" v-for="(rdrv, irv) in rd.runway_visibility" :key="'rv'+irv">{{ rdrv }}</td>
                        <td colspan="2" v-for="(rdpw, ipw) in rd.present_weather" :key="'pw'+ipw">{{ rdpw }}</td>
                        <td colspan="2" v-for="(rdsc, isc) in rd.sky_condition" :key="'sc'+isc">{{ rdsc }}</td>
                        <td colspan="2" v-for="(rdat, iat) in rd.air_temperature" :key="'at'+iat">{{ rdat }}</td>
                        <td colspan="2" v-for="(rddp, idp) in rd.dew_point" :key="'dp'+idp">{{ rddp }}</td>
                        <td colspan="2" v-for="(rdqnh, iqnh) in rd.qnh" :key="'qnh'+iqnh">{{ rdqnh }}</td>
                        <td colspan="2" v-for="(rdqfe, iqfe) in rd.qfe" :key="'qfe'+iqfe">{{ rdqfe }}</td>
                      </tr>
                    </tbody>
                    <tbody v-if="runways.length>1">
                      <tr v-for="(rd, ird) in rdata.rows" :key="ird">
                        <td class="text-left">{{ rd.observer_time }}</td>
                        <td v-for="(rdwd, iwd) in rd.wind_direction" :key="'wd'+iwd">{{ rdwd }}</td>
                        <td v-for="(rdws, iws) in rd.wind_speed" :key="'ws'+iws">{{ rdws }}</td>
                        <td v-for="(rdv, iv) in rd.visibility" :key="'v'+iv">{{ rdv }}</td>
                        <td v-for="(rdrv, irv) in rd.runway_visibility" :key="'rv'+irv">{{ rdrv }}</td>
                        <td v-for="(rdpw, ipw) in rd.present_weather" :key="'pw'+ipw">{{ rdpw }}</td>
                        <td v-for="(rdsc, isc) in rd.sky_condition" :key="'sc'+isc">{{ rdsc }}</td>
                        <td v-for="(rdat, iat) in rd.air_temperature" :key="'at'+iat">{{ rdat }}</td>
                        <td v-for="(rddp, idp) in rd.dew_point" :key="'dp'+idp">{{ rddp }}</td>
                        <td v-for="(rdqnh, iqnh) in rd.qnh" :key="'qnh'+iqnh">{{ rdqnh }}</td>
                        <td v-for="(rdqfe, iqfe) in rd.qfe" :key="'qfe'+iqfe">{{ rdqfe }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <span v-if="rdata.rows.length<1">No Data</span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'Reports',
  components: { DatePicker },
  props: {
  },
  created() {
    this.getAirport()
  },
  mounted() {
  },
  data () {
    return {
      isLoading: false, isHLoading: false,
      airports: [],
      airport_id: '',
      type_time: 'specific',
      show: '',
      runways: [],
      tblrunways: [],
      sdata: {
        meteorological: '',
        aerodrome_identification: '',
        wind_modified: '',
        visibility_modified: '',
        rvr_modified: '',
        present_weather_modified: '',
        sky_condition_modified: '',
        air_temperature_modified: '',
        qnh_modified: '',
        qfe_modified: '',
        timeIssue: ''
      },
      rdata: {
        airport: null,
        rows: [],
        runways: [],
        timerequest: ''
      },
      sform: {
        airport_id: '',
        time: '',
        download: null
      },
      rform: {
        airport_id: '',
        start_date: '',
        end_date: '',
        download: null
      },
      tableHeaders: [
        'Wind Direction', 'Wind Speed', 'Visibility', 'Runway Visibility', 'Present Weather', 'Sky Condition', 
        'Air Temperature', 'Dew Point', 'QNH', 'QFE'
      ],
      errors: {
        airport_id: '',
        time: '',
        start_date: '',
        end_date: ''
      }
    }
  },
  watch: {
    type_time: {
      handler: function (val, oldVal) {
        if(val!=oldVal){
          this.show = ''
          if(val=='range') {
            this.sform = { airport_id: '', time: '', download: null }
          } else {
            this.rform = { airport_id: '', start_date: '', end_date: '', download: null }
          }
        }
      }
    },
    airport_id: {
      handler: function (val, oldVal) {
        if(val!=oldVal){
          this.getListrunway(val)
        }
      }
    }
  },
  methods: {
    ...mapActions('airport', ['indexAPT']),
    ...mapActions('profile', ['showPFL']),
    ...mapActions('report', ['storeRST', 'storeRRT', 'printRST', 'printRRT']),
    async getAirport(){
        this.isHLoading = true
        await this.showPFL().then((response) => {
            response.airport_rights.forEach((item, index) => {
                this.airports[index] = { value: item.id, label: item.name+" ("+item.code+")", runway: item.runway_modified?item.runway_modified:[] }
            })
            this.isHLoading = false
            if(response.airport_rights.length>0) {
              this.airport_id = this.airports[0].value
            }
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
            this.isHLoading = false
        })
    },
    async showReport() {
      this.isLoading = true
      this.errors = { airport_id: '', time: '', start_date: '', end_date: '' }
      if(this.airport_id) {
        if(this.type_time != 'range') {
          this.sform.airport_id = this.airport_id
          this.sform.download = null
          await this.storeRST(this.sform).then((response) => {
            this.show = 'specific'
            if(response) {
              this.sdata.meteorological = response.meteorological
              this.sdata.aerodrome_identification = response.aerodrome_identification
              this.sdata.wind_modified = response.wind_modified
              this.sdata.visibility_modified = response.visibility_modified
              this.sdata.rvr_modified = response.rvr_modified
              this.sdata.present_weather_modified = response.present_weather_modified
              this.sdata.sky_condition_modified = response.sky_condition_modified
              this.sdata.air_temperature_modified = response.air_temperature_modified
              this.sdata.qnh_modified = response.qnh_modified
              this.sdata.qfe_modified = response.qfe_modified
              this.sdata.timeIssue = response.timeIssue
            }
            this.isLoading = false
          }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error.statusText })
            this.getError(error.data)
            this.isLoading = false
          })
        } else {
          this.rform.airport_id = this.airport_id
          this.rform.download = null
          await this.storeRRT(this.rform).then((response) => {
            this.show = 'range'
            if(response) {
              this.rdata.airport = response.airport
              this.rdata.rows = response.rows.length>0?response.rows:[]
              this.rdata.runways = response.runways.length>0?response.runways:[]
              this.rdata.timerequest = response.timeRequest
            }
            this.isLoading = false
          }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error.statusText })
            this.getError(error.data)
            this.isLoading = false
          })
        }
      }
    },
    async printReport(type) {
      if(type=='specific') {
        this.sform.download = 1
        await this.printRST(this.sform).then((response) => {
          window.open(response, '_blank')
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
        })
      } else {
        this.rform.download = 1
        await this.printRRT(this.rform).then((response) => {
          window.open(response, '_blank')
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
        })
      }
    },
    changeTime(time) {
      this.type_time = time
      this.errors = { airport_id: '', time: '', start_date: '', end_date: '' }
      if(time=='latest') {
        this.sform.time = 'latest'
      } else {
        this.sform.time = ''
      }
    },
    getListrunway(val) {
      this.tblrunways = []
      this.runways = []
      if(parseInt(val)>0) {
        var airport = this.airports.filter(function(item) {
          if(parseInt(val) == parseInt(item.value)) return true
        })
        if(airport.length>0) this.runways = airport[0].runway
        this.tableHeaders.forEach((ith, ih) => {
          if(this.runways.length>0) {
            this.runways.forEach((itr, ir) => {
              this.tblrunways.push(itr)
            })
          } else {
            this.tblrunways.push('-')
          }
        })
      }
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.airport_id = data.errors.airport_id ? data.errors.airport_id[0] : ''
        this.errors.time = data.errors.time ? data.errors.time[0] : ''
        this.errors.start_date = data.errors.start_date ? data.errors.start_date[0] : ''
        this.errors.end_date = data.errors.end_date ? data.errors.end_date[0] : ''
      } else { this.errors = { airport_id: '' }} }
    }
  }
}
</script>